/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = ({ author, authorImage }) => {
  return (
    <div className="flex mb-20">
      <Image
        className="mb-0 mr-4 rounded-full"
        fixed={authorImage}
        alt={author}
        style={{
          minWidth: 70,
        }}
      />
      <div className="flex flex-col justify-center">
        <span>
          Written by <strong>{author}</strong>
        </span>
      </div>
    </div>
  )
}

export default Bio
